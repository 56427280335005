<template>
  <a href="#" @click.prevent="handleClick" name="HeadSort">
    <i :class="cls"></i>
  </a>
</template>
<script>
/**
 * Sorting arrows within <th>
 */
export default {
  name: 'HeadSort',
  props: {
    field: { type: String, required: true },
    query: { type: Object, required: true }
  },
  data: () => ({
    order: ''
  }),
  computed: {
    cls () {
      const { order } = this
      return [
        'fa',
        { 'fa-sort text-muted': !order,
          'fa-sort-up': order === 'asc',
          'fa-sort-down': order === 'desc'
        }
      ]
    }
  },
  watch: {
    query: {
      handler ({ sort: field, order }) {
        this.order = field === this.field ? order : ''
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleClick () {
      const { query, order } = this
      query.sort = this.field
      query.order = this.order = order === 'desc' ? 'asc' : 'desc'
    }
  }
}
</script>
